import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-cards";

import logo from "../../assets/logo_bg_light.png";
import dolce from "../assets/NoDolce.png";
import menu from "../assets/NoMenu.png";
import dolceVero from "../assets/realDish.png";

import vapore from "../assets/vapore.png";
import burro from "../../assets/burroEspresso.jpg";
import flanTortino from "../../assets/FlanTortino.png";
import cocoaBall from "../../assets/CocoaBall.png";
import cocoaBall2 from "../../assets/CocoaBall2.png";
import henne from "../assets/PiattoHenne.png";
import henne2 from "../../assets/hennè2.jpeg";
import amarone from "../../assets/HenneAmarone.png";
import amarone2 from "../../assets/amarone2.jpg";
import cocktail1 from "../../assets/cocktail1.jpeg";
import cocktail2 from "../../assets/cocktail2.jpeg";
import cocktail3 from "../../assets/cocktail3.png";
import cocktail4 from "../../assets/cocktail.png";

import takeaway1 from "../../assets/takeaway1.png";
import takeaway2 from "../../assets/takeaway2.png";
import takeaway3 from "../../assets/takeaway3.png";

import flan2 from "../../assets/flanT2.jpeg";
import flan5 from "../../assets/flanT3.jpeg";
import flan3 from "../../assets/flanT4.jpeg";
import flan4 from "../../assets/flanT5.jpeg";

import styles from "./Home.module.scss";

const Home: React.FC = () => {
  const [amaroneImg, setAmaroneImg] = useState(false);

  const slider = document.getElementById("sliderImg");

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      <div
        className={`d-flex flex-column align-items-center justify-content-start overflow-hidden p-5 ${styles.pageContainer}`}
      >
        <div
          className={`d-flex flex-row align-items-center ${styles.logoContainer}`}
        >
          <img src={logo} />
        </div>
        {/* <div
          className={`d-flex flex-row mt-7 align-items-center ${styles.imageContainer}`}
        >
          <img className={`${styles.scritta}`} src={dolce} />
        </div> */}
        {/* <div
          className={`d-flex flex-row align-items-center  ${styles.imageContainer}`}
        >
          <img className={`${styles.immagine}`} src={dolceVero} />
        </div> */}
        {/* <div
          className={`d-flex flex-row align-items-center ${styles.imageContainer}`}
        >
          <img className={`${styles.scritta}`} src={menu} />
        </div> */}
        <div
          className={`d-flex flex-column align-items-center justify-content-center  col-12`}
        >
          {/* <span className="title-Sans color-black mt-10 text-start col-12 ">
            Cocktails <br />
            <span className="subtitle-Sans color-color-black ">
              Curativi / Lucidanti / <br />
            </span>
            <span className=" subtitle-Sans color-color-black">
              Rimpolpanti / <br />
            </span>
            <span className="subtitle-Sans color-color-black ">
              Nutritivi con Vapore
            </span>
          </span>

          <div
            className={`d-flex mt-4 flex-row align-items-center col-12 ${styles.bimageContainer} `}
          >
            <div
              className={`d-flex flex-row align-items-center justify-content-center  col-6 pe-3  ${styles.bimage}`}
            >
              <img src={cocktail} />
            </div>
            <div
              className={`d-flex flex-row align-items-center justify-content-center  col-6 ps-3 ${styles.bimage}`}
            >
              <img src={vapore} />
            </div>
          </div> */}

          <div className={`${styles.cocktail} mt-10`}>
            <div className={`${styles.cocktailImg} col-12`}>
              {/* <div className="col-6  " style={{ maxWidth: "50%" }}>
                <img src={cocktail} />
              </div> */}
              <div className="col-12 d-flex flex-column   justify-content-end align-items-start">
                <span className="title-Sans fw-bold">Cocktails</span>

                <span className="subtitle-Sans text-end color-black mt-5">
                  Curativi
                </span>
                <span className="subtitle-Sans text-end mt-2 color-color-black mt-3">
                  Lucidanti
                </span>
              </div>
            </div>

            <div className={`${styles.vaporeImg} col-12`}>
              <div className="col-12 d-flex flex-column  justify-content-start color-color-black">
                <span className="subtitle-Sans mt-3">Rimpolpanti</span>
                <span className="subtitle-Sans mt-3">Nutritivi con Vapore</span>
              </div>
              {/* <div className="col-6 text-end  " style={{ maxWidth: "50%" }}>
                <img src={cocktail2} />
              </div> */}
            </div>
            <div className={`  ${styles.flan} col-12 mt-7`}>
              {/* <img className="col-6 " src={flanTortino} /> */}
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[Autoplay, EffectCards]}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}
                className={`${styles.swiper}`}
              >
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocktail1} />
                </SwiperSlide>
                {/* <SwiperSlide className={`${styles.slide}`}>
                <img src={flanTortino} />
              </SwiperSlide> */}
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocktail2} />
                </SwiperSlide>
                <SwiperSlide
                  className={`${styles.slide}`}
                  style={{ objectFit: "fill" }}
                >
                  <img src={cocktail3} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocktail4} />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          {/* <div className={`${styles.treatmentImg} col-12 mt-10 `}>
            <img src={cocktail2} alt="cocktail" />
          </div> */}
          <div className="d-flex flex-row align-items-center justify-content-start col-12  mt-10">
            <span className="subtitle-Sans color-black col-12 ">
              Trattamento in cocktail lucidante
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
            <span className="text-Sans col-12 ">
              Miscela a base di polveri vegetali
            </span>
          </div>
          <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-color-black">
            <span className="text-Sans fw-bold col-4 ">Ingredienti</span>
            <span className="text-Sans ms-3 ">
              Camomilla, caffè, arancio, <br /> mousse lucidante.
            </span>
          </div>
          <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-black">
            <span className="text-Sans fw-bold col-4">Benefici</span>
            <span className="text-Sans ms-3 ">
              Lucida e toglie grigiore e opacità dal capello.
            </span>
          </div>
          <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-3">
            <span className="text-Sans fw-bold ">60,00 €{/* € */}</span>
          </div>
        </div>

        {/* <div className={`${styles.treatmentImg} col-12 mt-10 `}>
          <img src={cocktail} alt="cocktail" />
        </div> */}
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-10 ">
          <span className="subtitle-Sans color-black col-12 ">
            Trattamento in cocktail energizzante
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="text-Sans col-12  ">
            Miscela a base di polveri vegetali
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-color-black">
          <span className="text-Sans fw-bold col-4">Ingredienti</span>
          <span className="text-Sans ms-3 ">Anice, cannella e zenzero.</span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-black">
          <span className="text-Sans fw-bold col-4">Benefici</span>
          <span className="text-Sans ms-3 ">
            Rinforza e nutre in profondità.
            <br /> Agisce sulla cute e su un capello debole.
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-3">
          <span className="text-Sans fw-bold ">60,00 €{/* € */}</span>
        </div>

        {/* <div className={`${styles.treatmentImg} col-12 mt-10 `}>
          <img src={cocktail3} alt="cocktail" />
        </div> */}
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-10">
          <span className="subtitle-Sans color-black col-12 ">
            Trattamento in cocktail purificante
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="text-Sans col-12 ">
            Miscela a base di polveri vegetali ed erbe officinali
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-color-black">
          <span className="text-Sans fw-bold col-4">Ingredienti</span>
          <br />
          <span className="text-Sans ms-3 ">Salvia, menta e rosmarino.</span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-black">
          <span className="text-Sans fw-bold col-4">Benefici</span>
          <span className="text-Sans ms-3 ">
            Lenisce e purifica il cuoio capelluto.
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-3">
          <span className="text-Sans fw-bold ">60,00 €{/* € */}</span>
        </div>
        {/* <div className={`${styles.treatmentImg} col-12 mt-10 `}>
          <img src={cocktail4} alt="cocktail" />
        </div> */}
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-10">
          <span className="subtitle-Sans color-black col-12 ">
            Trattamento in cocktail idratante
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="text-Sans col-12  ">
            Miscela a base di polveri vegetali ed erbe officinali
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-color-black">
          <span className="text-Sans fw-bold col-4">Ingredienti</span>
          <br />
          <span className="text-Sans ms-3 ">
            Amido di riso, ananas, radice altea, burro di kariè/cocco, olio
            d'oliva.
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3 color-black">
          <span className="text-Sans fw-bold col-4">Benefici</span>
          <span className="text-Sans ms-3 ">
            Ha grandi proprietà emollienti, nutrienti ed idratanti.
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-3">
          <span className="text-Sans fw-bold color-black ">
            60,00 €{/* € */}
          </span>
        </div>

        <div className={`  ${styles.flan} col-12 mt-10`}>
          {/* <img className="col-6 " src={flanTortino} /> */}
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[Autoplay, EffectCards]}
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            className={`${styles.swiper}`}
          >
            <SwiperSlide className={`${styles.slide}`}>
              <img src={takeaway1} />
            </SwiperSlide>
            {/* <SwiperSlide className={`${styles.slide}`}>
                <img src={flanTortino} />
              </SwiperSlide> */}
            <SwiperSlide className={`${styles.slide}`}>
              <img src={takeaway2} />
            </SwiperSlide>
            <SwiperSlide className={`${styles.slide}`}>
              <img src={takeaway3} />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start mt-4 col-12">
          <span className="subtitle-Sans color-black col-12">
            Take Away / Delivery Kit
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-3 col-12">
          <span className="text-Sans col-12  color-black ">
            Mix di polveri vegetali ed erbe officinali e tintorie, amalgamate ad
            oli e burri ad alto potere nutriente, lucidante e idratante.
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-2 col-12">
          <span className="text-Sans col-12  color-black ">
            Ideale per rinvigorire un colore spento e opaco e dare luminosità. <br/>
            Questo mix è creato e personalizzato in base alla necessità.
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-4 col-12">
          <span className="text-Sans col-12  color-black ">
            Consulenza gratuita tramite WhatsApp inclusa
          </span>
        </div>

        <div className={`${styles.takeAway} mt-3 color-black`}>
          {/* <div className="text-Sans fw-bold   col-12">
            Le soluzioni sopra specificate sono disponibili anche in formula
            TAKE AWAY da asporto o a domicilio con spedizione
          </div> */}
          <div className="text-Sans fw-bold   col-12 text-end">50,00€</div>
        </div>
        <div className={` mt-10 ${styles.butter} `}>
          <img src={burro} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="subtitle-Sans color-black">
            Burro di karitè espresso
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="text-Sans ">
            Mousse di burro di karitè puro al 100% proveniente dal Burkina Faso.
            <br />
            Emulsionato con oli vegetali
          </span>
        </div>

        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3">
          <span className="text-Sans fw-bold col-4 color-black">Benefici</span>
          <span className="text-Sans ms-3 color-black">
            Idrata e ristruttura il capello
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-3">
          <span className="text-Sans fw-bold color-black ">
            55,00 €{/* € */}
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-10">
          <span className="subtitle-Sans color-black">
            Burro di Cocco e Cacao
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-3">
          <span className="text-Sans ">
            Mousse di cocco e cacao puro al 100%.
            <br />
            Emulsionato con oli vegetali
          </span>
        </div>

        <div className="d-flex flex-row align-items-start justify-content-start col-12 mt-3">
          <span className="text-Sans fw-bold col-4 color-black">Benefici</span>
          <span className="text-Sans ms-3  color-black">
            Idrata, nutre e ristruttura il capello
          </span>
        </div>
        <div className="d-flex flex-row align-items-start justify-content-end col-12 mt-4">
          <span className="text-Sans fw-bold color-black ">
            55,00 €{/* € */}
          </span>
        </div>

        <div className={`${styles.takeAway} mt-10 color-black`}>
          <div className="text-Sans fw-bold   col-12">
            Le soluzioni sopra specificate sono disponibili anche in formula
            TAKE AWAY da asporto o a domicilio con spedizione
          </div>
          <div className="text-Sans fw-bold  mt-3 col-12 text-end">30,00 €</div>
        </div>

        <div
          className={`mt-10 d-flex flex-row align-items-center justify-content-start col-12`}
        >
          <span className={`title-Sans fw-bold`}>Trattamenti Speciali</span>
        </div>

        <div
          className={`d-flex flex-column align-items-center justify-content-start col-12 mt-7`}
        >
          <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3`}
          >
            <span className={`subtitle-Sans`}>
              Ricostruzione all'acido Jaluronico e Sale Marino
            </span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3`}
          >
            <span className={`text-Sans`}>
              Trattamento per capelli destrutturati e danneggiati da schiariture
              o colorazioni chimiche, con olio di jojoba e vitamina E. <br />
              <br />
              <span className={`color-black`}>
                Ricostruisce la keratina del capello favorendone la crescita e
                il sigillo delle doppie punte, il capello risulterà da subito
                morbido e setoso al tatto e facile da pettinare.
              </span>
            </span>
          </div>
          <div
            className={`d-flex flex-row align-items-center justify-content-end col-12 mt-3`}
          >
            <span className={`text-Sans fw-bold`}>60,00 €</span>
          </div>
        </div>
        <div
          className={`d-flex flex-column align-items-center justify-content-start col-12 mt-10`}
        >
          <div className={`  ${styles.flan} col-12 `}>
            {/* <img className="col-6 " src={flanTortino} /> */}
            <Swiper
              effect={"cards"}
              grabCursor={true}
              modules={[Autoplay, EffectCards]}
              autoplay={{
                delay: 1500,
                disableOnInteraction: true,
              }}
              className={`${styles.swiper}`}
            >
              <SwiperSlide className={`${styles.slide}`}>
                <img src={flan2} />
              </SwiperSlide>
              {/* <SwiperSlide className={`${styles.slide}`}>
                <img src={flanTortino} />
              </SwiperSlide> */}
              <SwiperSlide className={`${styles.slide}`}>
                <img src={flan3} />
              </SwiperSlide>
              <SwiperSlide className={`${styles.slide}`}>
                <img src={flan4} />
              </SwiperSlide>
              <SwiperSlide className={`${styles.slide}`}>
                <img src={flan5} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="col-12 d-flex flex-row align-items-center  justify-content-start mt-3">
            <span className={`subtitle-Sans text-start`}>
              Flan tortino Hair Gourmet
            </span>
          </div>
          {/* <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3`}
          ></div> */}
          <div
            className={`d-flex flex-column align-items-center justify-content-start col-12 mt-5`}
          >
            <span className={`text-Sans color-black col-12`}>
              Tortino a base di erbe tintorie e medicinali ricoperto da
              cioccolato caldo. <br />
            </span>
            <span className="col-12 text-Sans mt-3">
              Miscela di Henné Gourmet con all’interno materie prime di
              eccellenza e pigmenti ad acqua in grado di ricostruire il capello
              nella lucidità e corposità. <br /> <br />
              <span className={`color-black col-12`}>
                L’Henné Gourmet grazie all’aggiunta di materie prime gourmet è
                una soluzione valida per riflessare in maniera naturale ma anche
                per rimpolpare la struttura del capello.
              </span>
            </span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-end col-12 mt-3`}
          >
            <span className={`text-Sans fw-bold`}>80,00 €</span>
          </div>
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-start col-12 mt-10"
          //   style={{ border: "1px solid red" }}
        >
          <div className={`col-12  ${styles.amarone} `}>
            <div
              className={`${styles.slider} d-flex flex-row col-12 align-items-center justify-content-center`}
            >
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[Autoplay, EffectCards]}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}
                className={`${styles.swiper}`}
              >
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={cocoaBall2} />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          {/* <div
              className={`col-6 d-flex flex-column align-items-start  justify-content-start `}
            >
              <img className={`${styles.cocoaImg}`} src={cocoaBall} />
              <div className="col-12 mt-3 "></div>
            </div>
            <div className="col-6 d-flex flex-column align-items-end  justify-content-start ">
              <img
                className={`col-10  ${styles.cocoaImg2} `}
                src={cocoaBall2}
              />
            </div> */}

          <span className={`subtitle-Sans  col-12 mt-4`}>
            Cocoa Ball <br />
          </span>
          <span className={`text-Sans  mt-4 col-12`}>
            Per i Maya il cioccolato era la “bevanda degli dei”.
          </span>
          <span className={`text-Sans mt-3`}>
            Noi lo abbiamo racchiuso con un’elegante composizione, insieme ad un
            cuore di burri vegetali ad altissimo potere nutriente.
          </span>
        </div>

        <div
          className={`d-flex flex-row align-items-center justify-content-end col-12 mt-3`}
        >
          <span className={`text-Sans fw-bold`}>80,00 €</span>
        </div>

        <div
          className={`d-flex flex-column align-items-start justify-content-start col-12 mt-10`}
        >
          <div className="d-flex flex-row align-items-center justify-content-start col-12 ">
            <div className={`col-12  ${styles.henne} `}>
              <img src={henne2} />
            </div>
          </div>

          <div className={`col-12 mt-4 text-start `}>
            <span className={`subtitle-Sans `}>Piatto Henné Gourmet</span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 mt-3`}
          >
            <span className={`text-Sans`}>
              Colorazione a base di erbe tintorie e officinali. <br />
              <br /> Miscela di Henné Gourmet con all’interno materie prime di
              eccellenza e pigmenti ad acqua in grado di ricostruire il capello
              nella lucidità e corposità.
              <br />
              <br />
              <span className={`color-black`}>
                L’Henné Gourmet grazie all’aggiunta di materie prime naturali,
                burri e oli è una soluzione valida per curare, nutrire,
                riflessare in maniera naturale ma anche per rimpolpare la
                struttura del capello e renderla più forte e sana.
              </span>
            </span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-end col-12 mt-3`}
          >
            <span className={`text-Sans fw-bold`}>da 80,00 / 95,00 €</span>
          </div>
        </div>

        <div
          className={`d-flex flex-column align-items-center justify-content-start col-12 mt-10`}
        >
          <div className={`col-12  ${styles.amarone} `}>
            <div
              className={`${styles.slider} d-flex flex-row col-12 align-items-center justify-content-center`}
            >
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[Autoplay, EffectCards]}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: true,
                }}
                className={`${styles.swiper}`}
              >
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone2} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone} />
                </SwiperSlide>
                <SwiperSlide className={`${styles.slide}`}>
                  <img src={amarone2} />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-start col-12 mt-4">
            <span className={`subtitle-Sans `}>
              Piatto di Hennè con Amarone
            </span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-start col-12 mt-4`}
          >
            <span className={`text-Sans`}>
              Trattamento a base di erbe tintorie e officinali e Amarone.
              <br />
              <br />
              <span className={`color-black`}>
                La vinoterapia sfrutta le proprietà benefiche del vino per
                contrastare l’effetto dei radicali liberi, stimola la crescita
                del capello e ha funzione energizzante. <br />
              </span>
              <div className="mt-2">
                <span>
                  Ricco di vitamina C e vitamina A e ferro è un ottimo
                  antiossidante e contrasta i radicali liberi.
                </span>
              </div>
            </span>
          </div>

          <div
            className={`d-flex flex-row align-items-center justify-content-end col-12 mt-3`}
          >
            <span className={`text-Sans fw-bold`}>da 80,00 / 95,00 €</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
