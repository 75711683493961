import styles from "../Landing/Landing.module.scss";

import logo from "../../assets/HairGourmetLogoWhite.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Landing: React.FC = () => {
  const redirect = useNavigate();

  useEffect(() => {
    console.log("caricoooo diocaaan");
  }, []);

  const clickSite = () => {
    // redirect("https://hairgourmet.it", {
    //   replace: true,
    // });
    window.location.href = "https://hairgourmet.it/";
  };

  const clickNewsletter = () => {
    // redirect("https://hairgourmet.it/iscrizione-newsletter/", {
    //   replace: true,
    // });
    window.location.href = "https://hairgourmet.it/iscrizione-newsletter/";
  };

  return (
    <>
      <div
        style={{ minHeight: "100vh", backgroundColor: "black" }}
        className="color-white"
      >
        <div
          className={`d-flex flex-row align-items-center justify-content-center ${styles.logoContainer}`}
          style={{ height: "30vh" }}
        >
          <img src={logo} />
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-center  "
          style={{ height: "60vh" }}
        >
          <div className="d-flex flex-row align-items-center justify-content-center col-12">
            <span
              className="title-Sans border p-4 col-8 text-center"
              onClick={() => redirect("/menu")}
            >
              MENU
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center mt-7 col-12">
            <span
              className="title-Sans border p-4 col-8 text-center"
              onClick={() => clickNewsletter()}
            >
              NEWSLETTER
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center mt-7 col-12">
            <span
              className="title-Sans border p-4 col-8 text-center"
              onClick={() => clickSite()}
            >
              SITO
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
