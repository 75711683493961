import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home/Home";
import Landing from "./routes/Landing/Landing";

const Container = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Landing />} />

      <Route path={"/menu"} element={<Home />} />
    </Routes>
  );
};

export default Container;
